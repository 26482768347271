import { Flex, Image, Stack } from '@chakra-ui/react';
import {
	Book,
	ChartLine,
	ChartPieSlice,
	Flag,
	Headset,
	House,
	Phone,
	Question,
	Strategy,
	Target,
} from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setReportIssueModalIsOpen } from '../../../redux/slice';
import { useIsManager, useIsOwner } from '../../hooks/useCheckRole';
import { useIsAdmin } from '../../hooks/useIsAdmin';
import { useIsRecruiting } from '../../hooks/useIsRecruiting';
import { MenuDivider } from './MenuDivider';
import { NavButton } from './NavButton';
import { ProfileButton } from './ProfileButton';

const HELP_CENTER_URL =
	'https://wejam.atlassian.net/servicedesk/customer/portal/1';
const SUPPORT_EMAIL = 'support@wejam.atlassian.net';

export const SideBarNavigation = ({ isOpen }: { isOpen: boolean }) => {
	const { t } = useTranslation('common');
	const isOwner = useIsOwner();
	const isManager = useIsManager();
	const isAdmin = useIsAdmin();
	const isOwnerOrManager = isOwner || isManager;
	const isRecruiting = useIsRecruiting();
	const dispatch = useDispatch();

	const onHelpCenter = () => {
		window.open(HELP_CENTER_URL, '_blank');
	};

	const onReportIssue = () => {
		dispatch(setReportIssueModalIsOpen(true));
	};

	const onContactSupport = () => {
		window.location.href = `mailto:${SUPPORT_EMAIL}`;
	};

	return (
		<Stack mt="3" h="100%" justifyContent={'space-between'} gap="4">
			<Stack gap="1">
				<MenuDivider text={isOpen ? t('sidebar.practice.divider') : ''} />
				<Stack gap="1" id="training-center-section">
					<NavButton
						disabled={isAdmin}
						icon={<House weight="bold" size={16} />}
						label={t('sidebar.practice.home')}
						hideLabel={!isOpen}
						href={'/users/home'}
					/>
					<NavButton
						disabled={isAdmin}
						icon={<ChartLine weight="bold" size={16} />}
						label={t('sidebar.practice.progress')}
						hideLabel={!isOpen}
						href={'/progress'}
					/>

					<NavButton
						hidden={isRecruiting}
						icon={<Book weight="bold" size={16} />}
						label={t('sidebar.practice.course_catalog')}
						hideLabel={!isOpen}
						href={'/course-catalog'}
					/>

					<NavButton
						hidden={isRecruiting}
						icon={<Strategy weight="bold" size={16} />}
						label={t('sidebar.practice.playbooks')}
						hideLabel={!isOpen}
						href={'/journeys'}
					/>
				</Stack>
				<MenuDivider
					hidden={isRecruiting}
					text={isOpen ? t('sidebar.insights.divider') : ''}
				/>
				<NavButton
					hidden={isRecruiting}
					icon={<Target weight="bold" size={16} />}
					disabled
					hideLabel={!isOpen}
					href=""
					label={t('sidebar.insights.my_skills')}
				/>
				<NavButton
					hidden={isRecruiting}
					icon={<Phone weight="bold" size={16} />}
					disabled={!isAdmin}
					hideLabel={!isOpen}
					href={'/real-call'}
					label={t('sidebar.insights.real_calls')}
				/>
				<MenuDivider
					hidden={!isOwnerOrManager}
					text={isOpen ? t('sidebar.analytics.divider') : ''}
				/>
				<NavButton
					hidden={!isOwnerOrManager}
					icon={<ChartPieSlice size={16} />}
					label={t('sidebar.analytics.activity')}
					hideLabel={!isOpen}
					href={'/dashboard'}
				/>
				<NavButton
					hidden={!isOwnerOrManager}
					icon={<Image src="/icons/performance-icon.svg" />}
					label={t('sidebar.analytics.performance')}
					hideLabel={!isOpen}
					href={'/performance'}
				/>
				<NavButton
					hidden={!isOwnerOrManager}
					icon={<Target weight="bold" size={16} />}
					hideLabel={!isOpen}
					label={t('sidebar.analytics.team')}
					href={'/teams'}
				/>
			</Stack>
			<Flex direction="column" gap={2}>
				<Stack gap="1" id="help-center-section">
					<NavButton
						icon={<Question weight="bold" size={16} />}
						hideLabel={!isOpen}
						onClick={onHelpCenter}
						label={t('sidebar.help')}
					/>
					<NavButton
						icon={<Headset weight="bold" size={16} />}
						hideLabel={!isOpen}
						onClick={onContactSupport}
						label={t('sidebar.contact')}
					/>
					<NavButton
						icon={<Flag weight="bold" size={16} />}
						hideLabel={!isOpen}
						onClick={onReportIssue}
						label={t('sidebar.report')}
					/>
				</Stack>
				<ProfileButton isOpen={isOpen} />
			</Flex>
		</Stack>
	);
};
