import {
	AbsoluteCenter,
	Box,
	Flex,
	ProgressCircleLabel,
	Stack,
} from '@chakra-ui/react';
import {
	PathsApiV1SessionsGetParametersQueryType,
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisItemsSolved,
	SessionResponseDTOAnalysisState,
} from '@jam/api-sdk/api';
import {
	AccordionItem,
	AccordionItemContent,
	AccordionItemTrigger,
	AccordionRoot,
	Chip,
	ProgressCircleRing,
	ProgressCircleRoot,
	ProgressCircleValueText,
	Text,
} from '@jam/front-library';
import { Trans, useTranslation } from 'react-i18next';
import { XpIcon } from '../../../common/components/Icons';
import { useIsOnboarding } from '../../../common/hooks/useIsOnboarding';
import { OnboardingFeature } from '../../../common/types/Onboarding';
import { useGetItemUserFeedbackBySessionIdQuery } from '../../../redux/api/userFeedbackApi';
import { FeedbackNotFinished } from './FeedbackNotFinished';
import { SessionUserFeedback } from './SessionUserFeedback';

type SessionFeedbackProps = {
	session: SchemaSessionResponseDto | null | undefined;
};

const SolvedStatusToColorMap: Record<
	SessionResponseDTOAnalysisItemsSolved,
	{
		bg: string;
		color: string;
	}
> = {
	[SessionResponseDTOAnalysisItemsSolved.solved]: {
		bg: '#EAFDEE',
		color: '#147129',
	},
	[SessionResponseDTOAnalysisItemsSolved.partlySolved]: {
		bg: 'yellow.200',
		color: '#757575',
	},
	[SessionResponseDTOAnalysisItemsSolved.notSolved]: {
		bg: 'rgba(255, 0, 0, 0.1)',
		color: 'rgba(255, 0, 0, 0.6)',
	},
	[SessionResponseDTOAnalysisItemsSolved.undetected]: {
		bg: '#F5F5F5',
		color: '#757575',
	},
};

const langProps: Record<
	string,
	{
		text: string;
		helper: string;
	}
> = {
	solved_some_tasks: {
		text: 'call:solved_some_tasks',
		helper: 'call:solved_some_tasks_helper',
	},
	solved_all_tasks: {
		text: 'call:solved_all_tasks',
		helper: 'call:solved_all_tasks_helper',
	},
	solved_no_tasks: {
		text: 'call:solved_no_tasks',
		helper: 'call:solved_no_tasks_helper',
	},
};

export const SessionFeedback = ({ session }: SessionFeedbackProps) => {
	const { t } = useTranslation('call');
	const isOnboarding = useIsOnboarding(OnboardingFeature.CALL_PAGE);

	const { data: userFeedbacksPerItem } = useGetItemUserFeedbackBySessionIdQuery(
		session?.id ?? '',
		{
			skip: !session,
		}
	);

	if (!session) {
		return null;
	}

	if (
		!session.analysis ||
		session.analysis.state !== SessionResponseDTOAnalysisState.DONE
	) {
		// session is stuck in pending for more than 30 minutes give up
		if (new Date(session.createdAt).getTime() + 1000 * 60 * 30 < Date.now()) {
			return <FeedbackNotFinished status="STALLED" />;
		}

		return <FeedbackNotFinished status="PENDING" />;
	}

	const completedItems =
		session?.analysis?.items?.filter(
			(item) => item.solved === SessionResponseDTOAnalysisItemsSolved.solved
		)?.length || 0;

	const allItems = session?.analysis?.items?.length || 0;

	let tasksStatusKey = 'solved_some_tasks';
	if (completedItems === allItems) {
		tasksStatusKey = 'solved_all_tasks';
	} else if (completedItems === 0) {
		tasksStatusKey = 'solved_no_tasks';
	}

	const items = session.analysis.items;

	const notSolvedItemsIndexes = items
		.map((item, index) =>
			item.solved !== SessionResponseDTOAnalysisItemsSolved.solved ? index : -1
		)
		.filter((x) => x >= 0);

	// open all items in onboarding
	const openedIndexes = isOnboarding
		? Array.from(Array(items.length).keys())
		: notSolvedItemsIndexes;

	const shouldShowSummary =
		allItems > 0 &&
		session.type === PathsApiV1SessionsGetParametersQueryType.ROLE_PLAY;

	return (
		<Flex direction={'column'} gap="4">
			{shouldShowSummary && (
				<Flex
					border={'1px solid'}
					borderColor={'gray.300'}
					bg={'#FAFAFA'}
					px="4"
					py="10px"
					gap="6"
					borderRadius={'24px'}
					alignItems={'center'}
				>
					<ProgressCircleRoot
						value={Math.round(session.analysis.score)}
						color="#4241E4"
						colorPalette="blue"
						size={'xl'}
					>
						<AbsoluteCenter>
							<ProgressCircleValueText fontSize={'16px'} />
						</AbsoluteCenter>
						<ProgressCircleLabel />
						<ProgressCircleRing />
					</ProgressCircleRoot>
					<Flex gap={1} direction={'column'}>
						<Box fontWeight={'500'}>
							<Trans
								i18nKey={langProps[tasksStatusKey]?.text ?? ''}
								values={{
									completedItems,
									allItems,
									xp: session.completed ? session.storyXp : session.sessionXp,
								}}
							>
								Good effort! You solved
								<Stack display={'inline'}>
									<Text display={'inline'} color="#4241E4">
										{`${completedItems}/${allItems}`} items
									</Text>
								</Stack>
								and earned 20 <XpIcon height="1em" width="1em" mb="2px" />.
								<Text variant="small-print">
									Review your feedback and try again.
								</Text>
							</Trans>
						</Box>
						<Text fontSize={'14px'} color={'#757575'}>
							{t(langProps[tasksStatusKey]?.helper)}
						</Text>
					</Flex>
				</Flex>
			)}
			<AccordionRoot
				multiple
				defaultValue={openedIndexes as unknown as string[]}
			>
				{items.map((item) => {
					const userFeedback = userFeedbacksPerItem
						? userFeedbacksPerItem?.data.find(
								(x) => x.storyItemReferenceId === item.id
							) ?? null
						: undefined;

					return (
						<AccordionItem mb="5" key={item.id} border={0} value={item.id}>
							<Box
								borderRadius={'24px'}
								border={'1px solid'}
								borderColor={'#D9D9D9'}
								px="4"
								py="6"
							>
								<AccordionItemTrigger width={'100%'} indicatorPlacement="start">
									<Flex w="100%" alignItems="center">
										<Text className="ml-4 w-fit text-base font-medium text-black">
											{item.name}
										</Text>
										<Chip
											ml="auto"
											w="fit-content"
											border={0}
											py="1"
											px={2}
											fontSize={'12px'}
											fontWeight={'500'}
											textColor={
												SolvedStatusToColorMap[
													item.solved ??
														SessionResponseDTOAnalysisItemsSolved.undetected
												].color
											}
											bg={
												SolvedStatusToColorMap[
													item.solved ??
														SessionResponseDTOAnalysisItemsSolved.undetected
												].bg
											}
											text={t(
												item.solved ??
													SessionResponseDTOAnalysisItemsSolved.undetected
											)}
										/>
									</Flex>
								</AccordionItemTrigger>
								<AccordionItemContent pt={6}>
									<Stack direction={'row'} gap={4}>
										<Flex w="50%" direction={'column'} gap="2">
											<Text fontSize="16px" color="black" fontWeight="500">
												{t('task_feedback')}
											</Text>
											<Text color="black" fontSize="14px" fontWeight="400">
												{item.explanation}
											</Text>
										</Flex>
										<Flex w="50%" direction={'column'} gap="2">
											<Text fontSize="16px" color="black" fontWeight="500">
												{t('nextTime')}
											</Text>
											<Text color="black" fontSize="14px" fontWeight="400">
												{item.suggestion}
											</Text>
										</Flex>
									</Stack>
									<Box className="mt-8">
										<SessionUserFeedback
											sessionId={session.id}
											item={item}
											userFeedback={userFeedback}
										/>
									</Box>
								</AccordionItemContent>
							</Box>
						</AccordionItem>
					);
				})}
			</AccordionRoot>
		</Flex>
	);
};
