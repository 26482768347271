import { Box, Image, useDisclosure, VStack } from '@chakra-ui/react';
import {
	Button,
	DialogBackdrop,
	DialogBody,
	DialogContent,
	DialogRoot,
	Text,
} from '@jam/front-library';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsOnboarding } from '../../hooks/useIsOnboarding';
import { OnboardingFeature } from '../../types/Onboarding';

export const OnboardingModal = () => {
	const { open: isOpen, onOpen, onClose } = useDisclosure();
	const { setIsOpen } = useTour();
	const isOnboarding = useIsOnboarding(OnboardingFeature.HOME_PAGE);
	const { t } = useTranslation('common');

	useEffect(() => {
		if (isOnboarding) {
			onOpen();
		}
	}, [isOnboarding, onOpen]);

	const onGetStarted = () => {
		onClose();
		setTimeout(() => {
			setIsOpen(true);
		}, 200);
	};

	return (
		<DialogRoot
			open={isOpen}
			onOpenChange={({ open }) => {
				if (!open) {
					onClose();
				}
			}}
			placement="center"
		>
			<DialogBackdrop />
			<DialogContent maxW="450px" py={6} borderRadius={'24px'}>
				<DialogBody p={0}>
					<VStack gap={4} align="center">
						<Image src="/logo-color.svg" alt="Jam Logo" w="24px" h="24px" />
						<Text mx={6} fontSize="xl" fontWeight="bold">
							{t('onboarding.welcome')}
						</Text>
						<Text mx={6} textAlign="center" color="gray.600">
							{t('onboarding.description')}
						</Text>
						<Box width={'full'} px={6}>
							<Button onClick={onGetStarted} size="full">
								{t('onboarding.getStarted')}
							</Button>
						</Box>
					</VStack>
				</DialogBody>
			</DialogContent>
		</DialogRoot>
	);
};
