import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../redux/hooks';
import { selectMenuIsOpen, toggleMenu } from '../../../redux/slice';
import { ExpandableSidebar } from './ExpandableSideBar';
import { SideBarNavigation } from './SideBarNavigation';
import { Logo } from '@jam/front-library';

export const Sidebar = () => {
	const isOpen = useAppSelector(selectMenuIsOpen);
	const dispatch = useDispatch();

	const onToggle = () => {
		dispatch(toggleMenu());
	};

	return (
		<ExpandableSidebar isOpen={isOpen} onToggle={onToggle}>
			<Logo size={isOpen ? 'full' : 'icon'} />
			<SideBarNavigation isOpen={isOpen} />
		</ExpandableSidebar>
	);
};
